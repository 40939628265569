<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Lugares</span>
      </v-col>
      <v-col>
        <v-btn to="/lugar/alta" icon small absolute right fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row
      ><v-col>
        <LugaresFilter @lugares-changed="sendLugares" :pageNumber="pageNumber">
        </LugaresFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <LugaresTable
          ref="lugaresTable"
          :lugares="this.lugares.results"
          :lugaresCount="this.lugares.count"
          @paginate="handlePagination"
        >
        </LugaresTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LugaresTable from "../components/LugaresTable.vue";
import LugaresFilter from "../components/LugaresFilter.vue";

export default {
  name: "Lugares",
  components: {
    LugaresTable,
    LugaresFilter,
  },
  data() {
    return {
      ingresos: null,
      lugares: [],
      pageNumber: "1",
      loading: false,
    };
  },
  methods: {
    sendLugares(lugares) {
      // this.pageNumber = "1";
      this.lugares = lugares;
      this.$refs.lugaresTable.loading = false;
    },

    handlePagination(pageNumber) {
      //console.log("paginate to: " + pageNumber);
      this.pageNumber = pageNumber;
    },
  },
};
</script>
