<template>
  <div>
    <v-expansion-panels flat tile>
      <v-expansion-panel>
        <v-expansion-panel-header>Filtro</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @submit.prevent="doFilter">
            <v-row>
              <v-col cols="10" md="3">
                <v-text-field label="Texto" v-model="search"></v-text-field>
              </v-col>
              <v-col cols="2" md="1" class="col-btn-filter">
                <v-btn
                  icon
                  type="submit"
                  color="secondary"
                  dark
                  @click="doFilter(true)"
                  ><v-icon>mdi-text-search</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "SectoresFilter",
  data() {
    return {
      search: "",
      sectores: [],
    };
  },
  props: {
    pageNumber: {
      type: String,
      required: false,
    },
  },
  created() {
    this.doFilter();
  },
  methods: {
    doFilter(resetPage = false) {
      let page = "1";
      if (resetPage) page = 1;
      else page = this.pageNumber;

      this.loading = true;
      EmpleadosService.getSectores(this.search, page)
        .then((response) => {
          this.sectores = response.data;
          this.$emit("sectores-changed", this.sectores);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
      //console.log("haciendo filter con page " + this.pageNumber);
    },
  },
  watch: {
    pageNumber: function (newVal, oldVal) {
      // watch it
      if (newVal && newVal.length) this.doFilter();
    },
  },
};
</script>

<style>
.col-btn-filter {
  padding-top: 25px;
}
</style>
