<template>
  <v-container>
    <v-row>
      <v-col style="display: flex">
        <span class="text-h4">Sectores</span>
      </v-col>
      <v-col>
        <v-btn to="sector/alta" icon small absolute right fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row
      ><v-col>
        <SectoresFilter
          @sectores-changed="sendSectores"
          :pageNumber="pageNumber"
        >
        </SectoresFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <SectoresTable
          ref="sectoresTable"
          :sectores="this.sectores.results"
          :sectoresCount="this.sectores.count"
          @paginate="handlePagination"
        >
        </SectoresTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SectoresTable from "../components/SectoresTable.vue";
import SectoresFilter from "../components/SectoresFilter.vue";

export default {
  name: "Sectores",
  components: {
    SectoresTable,
    SectoresFilter,
  },
  data() {
    return {
      ingresos: null,
      sectores: [],
      pageNumber: "1",
      loading: false,
    };
  },
  methods: {
    sendSectores(sectores) {
      // this.pageNumber = "1";
      this.sectores = sectores;
      this.$refs.sectoresTable.loading = false;
    },

    handlePagination(pageNumber) {
      //console.log("paginate to: " + pageNumber);
      this.pageNumber = pageNumber;
    },
  },
};
</script>
