<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Empleados</span>
      </v-col>
      <v-col>
        <v-btn to="/empleados/alta" icon small absolute right fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-row
      ><v-col>
        <EmpleadosFilter
          @empleados-changed="sendempleados"
          :pageNumber="pageNumber"
        >
        </EmpleadosFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <EmpleadosTable
          ref="personaTable"
          :empleados="this.empleados.results"
          :empleadosCount="this.empleados.count"
          @paginate="handlePagination"
        >
        </EmpleadosTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EmpleadosTable from "../components/EmpleadosTable";
import EmpleadosFilter from "../components/EmpleadosFilter.vue";

export default {
  name: "Empleados",
  components: {
    EmpleadosTable,
    EmpleadosFilter,
  },
  data() {
    return {
      ingresos: null,
      empleados: [],
      pageNumber: "1",
      loading: false,
    };
  },
  methods: {
    sendempleados(empleados) {
      // this.pageNumber = "1";
      this.empleados = empleados;
      this.$refs.personaTable.loading = false;
    },

    handlePagination(pageNumber) {
      //console.log("paginate to: " + pageNumber);
      this.pageNumber = pageNumber;
    },
  },
};
</script>
