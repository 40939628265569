<template>
  <v-app>
    <v-navigation-drawer
      v-if="permission"
      app
      clipped
      v-model="drawer"
      width="200"
    >
      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="link in links_menu"
          :key="link.title"
          link
          :to="{ name: link.link_name }"
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ link.title }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>ControlTSS - Empleados</v-toolbar-title>
      <div class="d-flex align-center"></div>
      <v-spacer></v-spacer>
      <v-menu v-if="username" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed color="primary" dark v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
            <span class="mr-2">{{ username }}</span>
          </v-btn>
        </template>
        <v-list nav dense>
          <v-list-item link to="ChangePassword" key="1">
            <v-list-item-icon>
              <v-icon>mdi-key</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cambiar Contraseña</v-list-item-title>
          </v-list-item>
          <v-list-item key="2" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <v-footer padless app color="primary">
      <v-col class="primary text-center white--text" cols="12">
        {{ new Date().getFullYear() }} — <strong>TSS Group SRL</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { authComputed } from "./store/helpers.js";
export default {
  name: "App",

  computed: {
    ...authComputed,
  },
  data: () => ({
    links_menu: [
      {
        title: "Fichadas",
        link_name: "Fichadas",
        icon: "mdi-clipboard-text-clock",
      },
      {
        title: "Empleados",
        link_name: "Empleados",
        icon: "mdi-account-multiple",
      },

      {
        title: "Sectores",
        link_name: "Sectores",
        icon: "mdi-office-building",
      },

      {
        title: "Lugares",
        link_name: "Lugares",
        icon: "mdi-map-marker-multiple ",
      },
      {
        title: "Dispositivos",
        link_name: "Dispositivos",
        icon: "mdi-devices",
      },
    ],
    right: null,
    drawer: true,
    group: null,
    username: null,
    permission: false,
  }),
  updated() {
    this.getUserName();
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },

    getUserName() {
      let loggedIn = localStorage.getItem("user");
      if (loggedIn) {
        this.username = JSON.parse(loggedIn).username;
        this.permission = JSON.parse(loggedIn).permission;
      }
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
