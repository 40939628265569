import Vue from "vue";
import VueRouter from "vue-router";
import Empleados from "../views/Empleados.vue";
import Sectores from "../views/Sectores.vue";
// import Dispositivos from "../views/Dispositivos.vue";
import Lugares from "../views/Lugares.vue";
// import LoginPage from "../views/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/fichadas",
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      hideForAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginPage.vue"),
  },
  {
    path: "/permisosError",
    name: "PermisosError",
    meta: { requiresAuth: true, permError: true },
    component: () =>
      import(
        /* webpackChunkName: "permisosError" */ "../views/PermisosError.vue"
      ),
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/PasswordChangePage.vue"),
  },
  {
    path: "/changePasswordDispositivo/:dispositivo_id",
    name: "ChangePasswordDispositivo",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/DispositivoPasswordChange.vue"
      ),
  },
  {
    path: "/fichadas",
    name: "Fichadas",
    meta: { requiresAuth: true, requiresPerm: true },
    component: () =>
      import(/* webpackChunkName: "Fichadas" */ "../views/Fichadas.vue"),
  },
  {
    path: "/fichada/:id",
    name: "FichadaDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "FichadaDetalle" */ "../views/FichadaDetalle.vue"
      ),
  },
  {
    path: "/sectores",
    name: "Sectores",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "Sectores" */ "../views/Sectores.vue"),
  },
  {
    path: "/sector/alta",
    name: "SectorAlta",
    meta: { requiresAuth: true },
    // props: true,
    component: () =>
      import(/* webpackChunkName: "SectorAlta" */ "../views/SectorAlta.vue"),
  },
  {
    path: "/sector/:id",
    name: "SectorDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "SectorDetalle" */ "../views/SectorDetalle.vue"
      ),
  },
  {
    path: "/sector/:id/editar",
    name: "SectorEdit",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "SectorEdit" */ "../views/SectorEdit.vue"),
  },
  {
    path: "/dispositivos",
    name: "Dispositivos",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "accesos" */ "../views/Dispositivos.vue"),
  },
  {
    path: "/dispositivo/alta",
    name: "DispositivoAlta",
    meta: { requiresAuth: true },
    // props: true,
    component: () =>
      import(
        /* webpackChunkName: "PersonaAlta" */ "../views/DispositivoAlta.vue"
      ),
  },
  {
    path: "/dispositivo/:id/editar",
    name: "DispositivoEdit",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "SectorEdit" */ "../views/DispositivoEdit.vue"
      ),
  },
  {
    path: "/dispositivo/:id",
    name: "DispositivoDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PersonaDetalle" */ "../views/DispositivoDetalle.vue"
      ),
  },
  {
    path: "/lugares",
    name: "Lugares",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "accesos" */ "../views/Lugares.vue"),
  },
  {
    path: "/lugar/alta",
    name: "LugarAlta",
    meta: { requiresAuth: true },
    // props: true,
    component: () =>
      import(/* webpackChunkName: "PersonaAlta" */ "../views/LugarAlta.vue"),
  },
  {
    path: "/lugar/:id",
    name: "LugarDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PersonaDetalle" */ "../views/LugarDetalle.vue"
      ),
  },
  {
    path: "/lugar/:id/editar",
    name: "LugarEdit",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "SectorEdit" */ "../views/LugarEdit.vue"),
  },
  {
    path: "/empleados",
    name: "Empleados",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "Empleados" */ "../views/Empleados.vue"),
  },
  {
    path: "/empleado/:id",
    name: "EmpleadoDetalle",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PersonaDetalle" */ "../views/EmpleadoDetalle.vue"
      ),
  },
  {
    path: "/empleado/:id/editar",
    name: "EmpleadoEdit",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(/* webpackChunkName: "SectorEdit" */ "../views/EmpleadoEdit.vue"),
  },
  {
    path: "/empleado/:id/enrolar/:nuevo",
    name: "EmpleadoEnrolar",
    meta: { requiresAuth: true },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "PersonaAlta" */ "../views/EmpleadoEnrolar.vue"
      ),
  },
  {
    path: "/empleados/alta",
    name: "EmpleadoAlta",
    meta: { requiresAuth: true },
    // props: true,
    component: () =>
      import(/* webpackChunkName: "PersonaAlta" */ "../views/EmpleadoAlta.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  let permission = false;
  //console.log("loggedIn?: " + loggedIn);
  if (loggedIn) {
    permission = JSON.parse(loggedIn).permission;
  } else permission = false;

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/login");
  } else if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    to.matched.some((record) => !record.meta.permError) &&
    loggedIn &&
    !permission
  ) {
    next("/permisosError");
  } else if (to.matched.some((record) => record.meta.hideForAuth) && loggedIn) {
    next("/");
  } else next();

  // ...

  // router.beforeResolve((to, from, next) => {
  //   // If this isn't an initial page load.
  //   if (to.name) {
  //     // Start the route progress bar.
  //     NProgress.start();
  //   }
  //   next();
  // });

  // router.afterEach((to, from) => {
  //   // Complete the animation of the route progress bar.
  //   NProgress.done();
  // });
});

export default router;
